import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`True Grit 4 is filled with teams ready to rumble for 1st place today
at The Ville! Come out and watch the action starting at 9:00am. For
schedule and heat info go to our True Grit 4 Facebook page or
`}<a parentName="strong" {...{
          "href": "https://truegritcomp.wordpress.com"
        }}>{`https://truegritcomp.wordpress.com`}</a></strong></p>
    <p><strong parentName="p">{`*`}{`Louisville East will have the normal 10:00 & 11:00am class times but
there will be no CrossFit Kids class at 9:30. The Ville will have no
classes or open gym so we can host True Grit 4. `}</strong></p>
    <p>{`Push Press 8-8-8-8\\@70%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 rounds for time of:`}</p>
    <p>{`9-S2OH (135/95)`}</p>
    <p>{`7-Ring Muscle Ups`}</p>
    <p>{`50-Situps`}</p>
    <p><strong parentName="p">{`*`}{`We are collecting blankets, scarves and gloves at Louisville East
and The Ville for the homeless this winter. If you’d like to donate
please do so by November 12th. There are bins at each location for your
donations. Thanks!`}</strong></p>
    <p><strong parentName="p">{`*`}{`The next Strength Program starts Sunday, November 6th. For more info
contact Eric at fallscitystrength\\@gmail.com.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      